import React from "react";
import axios from "axios";

export default class BlogPostPage extends React.Component {
  //What are going to ve our vars
  state = {
    title: "",
    text: "",
    images: "",
  };

  //Handle changes on inputs
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  //Handle submit button
  handleSubmit = (event) => {
    event.preventDefault();
    console.log(this.state);
    alert(
      `title :  ${this.state.title} ${this.state.text} ${this.state.images}!`
    );
    console.log(this.state);

    axios({
      method: "post",
      url: `https://testserver.heliotvilchis.com/blogpost.php`,
      headers: { "content-type": "application/json" },
      data: this.state,
    });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          Title
          <input
            type="text"
            name="title"
            value={this.state.title}
            onChange={this.handleInputChange}
          />
        </label>
        <label>
          Text
          <input
            type="text"
            name="text"
            value={this.state.text}
            onChange={this.handleInputChange}
          />
        </label>
        <label>
          Images
          <input
            type="text"
            name="images"
            value={this.state.images}
            onChange={this.handleInputChange}
          />
        </label>
        <button type="submit">Submit</button>
      </form>
    );
  }
}
